import OrdersModel from "../pages/orders/model/OrdersModel";
import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";
import DateUtility from "../utils/date-utility";

const BASE_API = process.env.REACT_APP_BASE_API;

const getListOrder = async (data) => {
  const url = BASE_API + Endpoint.listOrder;
  const response = await CallApi({ url, method: "POST", data });
  const res1 = response !== "undefined" ? response.data : null;
  const res2 = res1 !== null ? res1.data : null;
  const list = res2 !== null ? res2.content : null;

  let array = [];
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    const item = new OrdersModel({
      id: element.orderMerchantId,
      status: element.orderStatus,
      invoice: element.invoiceNumber,
      customerName: element.customerName,
      dateCreated: DateUtility.formatDate(element.orderDate, "full"),
      paymentMethod: element.paymentMethod + " " + element.paymentChannel,
      address: element.receiverAddress,
      courier: element.shipmentProvider + " - " + element.serviceCode,
      receiptNumber: element.receiptNumber,
      receivedBy: element.receivedBy,
      receivedDate:
        element.receivedDate !== ""
          ? DateUtility.formatDate(element.receivedDate, "half")
          : "",
      product: element.products,
      shipping: element.totalShippingCost,
      fee: element.applicationServiceFee,
      insuranceFee: element.totalShippingInsurance,
      complaint: element.remarkComplaint,
      total: element.totalPrice,
      commission: element.commission,
    });
    array.push(item);
  }

  let obj = {};
  obj.error = response.error;
  obj.message = response.message;
  obj.hasMore = res1.hasMore;
  obj.data = array;

  return obj;
};

const getTrackingOrder = async (data) => {
  const url = BASE_API + Endpoint.trackingOrder;
  const response = await CallApi({ url, method: "POST", data });
  const res1 = response !== "undefined" ? response.data : null;
  const res2 = res1 !== null ? res1.data : null;

  let obj = {};
  obj.error = response.error;
  obj.message = response.message;
  obj.data = res2;

  return obj;
};

const setPickUpOrder = async (data) => {
  const url = BASE_API + Endpoint.setPickupOrder;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const getResi = async (data) => {
  const url = BASE_API + Endpoint.getResi;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const OrderRepository = {
  getListOrder,
  getTrackingOrder,
  setPickUpOrder,
  getResi,
};

export default OrderRepository;

import React from "react";

const ButtonGreen = ({ title, onClick }) => {
  return (
    <>
      <button
        type="submit"
        className="button md:w-36 hover:bg-gray-primary hover:text-green-secondary text-xs tracking-widest font-bold text-gray-primary border-2 border-green-secondary bg-green-secondary border-opacity-40 rounded-xl py-2"
        onClick={(e) => {
          onClick(e);
        }}
      >
        {title.toUpperCase()}
      </button>
    </>
  );
};

export default ButtonGreen;

import BankModel from "../pages/auth/register/model/BankModel";
import PhoneModel from "../pages/auth/register/model/PhoneModel";
import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";
import GeneralUtility from "../utils/general-utility";

const BASE_API = process.env.REACT_APP_BASE_API;

const getTnC = async (data) => {
  const url = BASE_API + Endpoint.tnc;
  const response = await CallApi({ url, method: "POST", data: data });
  return response;
};

const getProvince = async () => {
  const url = BASE_API + Endpoint.province;
  const response = await CallApi({ url, method: "POST" });
  const data = typeof response != "undefined" ? response.data : null;
  const data2 = typeof data != "undefined" && data != null ? data.data : [];
  return data2;
};

const getCity = async (dat) => {
  const url = BASE_API + Endpoint.city;
  const response = await CallApi({ url, method: "POST", data: dat });
  const data = typeof response != "undefined" ? response.data : null;
  const data2 = typeof data != "undefined" && data != null ? data.data : [];
  return data2;
};

const getDistrict = async (dat) => {
  const url = BASE_API + Endpoint.district;
  const response = await CallApi({ url, method: "POST", data: dat });
  const data = typeof response != "undefined" ? response.data : null;
  const data2 = typeof data != "undefined" && data != null ? data.data : [];
  return data2;
};

const getCodePhone = async () => {
  const url = BASE_API + Endpoint.code_phone;
  const response = await CallApi({ url, method: "POST" });
  const data = typeof response != "undefined" ? response.data : null;
  const data2 = typeof data != "undefined" && data != null ? data.data : [];
  let array = [];
  data2.forEach((element) => {
    let phone = new PhoneModel({
      value: element.id,
      label: element.dialCode,
      code: element.code,
      image: element.image,
      name: element.name,
    });
    array.push(phone);
  });
  return array;
};

const getBank = async () => {
  const url = BASE_API + Endpoint.bank;
  const response = await CallApi({ url, method: "POST" });
  const data = typeof response != "undefined" ? response.data : null;
  const data2 = typeof data != "undefined" && data != null ? data.data : [];
  let array = [];
  data2.forEach((element) => {
    let bank = new BankModel({
      value: element.id,
      label: element.name,
      code: element.code,
    });
    array.push(bank);
  });
  return array;
};

const signUp = async (data) => {
  let form = new FormData();
  form.append(
    "photo",
    GeneralUtility.dataURLtoFile(data.photo, data.photoName),
    data.photoName
  );
  form.append(
    "ktpFile",
    GeneralUtility.dataURLtoFile(data.ktpFile, data.ktpFileName),
    data.ktpFileName
  );
  if (data.npwpFile) {
    form.append(
      "npwpFile",
      GeneralUtility.dataURLtoFile(data.npwpFile, data.npwpFileName),
      data.npwpFileName
    );
  }
  if (data.npwpNumber) {
    form.append("npwpNumber", data.npwpNumber);
  }
  form.append("name", data.name);
  form.append("email", data.email);
  form.append("ownerEmail", data.ownerEmail);
  form.append("phoneNumber", data.phoneNumber);
  form.append("countryCodeId", data.countryCodeId);
  form.append("address", data.address);
  form.append("provincesId", data.provincesId);
  form.append("citiesId", data.citiesId);
  form.append("districtsId", data.districtsId);
  form.append("postalCode", data.postalCode);
  form.append("bankName", data.bankName);
  form.append("bankNumber", data.bankNumber);
  form.append("bankId", data.bankId);
  form.append("ktpNik", data.ktpNik);
  form.append("merchantNib", data.merchantNib);
  form.append("password", data.password);
  form.append("tnc", data.tnc);
  form.append("dng", data.dng);

  const url = BASE_API + Endpoint.signup;
  const response = await CallApi({
    url,
    method: "POST",
    data: form,
    type: "multipart",
  });
  return response;
};

const checkAccDetail = async (data) => {
  let form = new FormData();
  form.append(
    "photo",
    GeneralUtility.dataURLtoFile(data.photo, data.photoName),
    data.photoName
  );
  form.append("name", data.name);
  form.append("email", data.email);
  form.append("ownerEmail", data.ownerEmail);
  form.append("countryCodeId", data.countryCodeId);
  form.append("phoneNumber", data.phoneNumber);
  form.append("address", data.address);
  form.append("provincesId", data.provincesId);
  form.append("citiesId", data.citiesId);
  form.append("districtsId", data.districtsId);
  form.append("postalCode", data.postalCode);
  const url = BASE_API + Endpoint.checkAccDetail;
  const response = await CallApi({
    url,
    method: "POST",
    data: form,
    type: "multipart",
  });
  return response;
};

const checkLegalDoc = async (data) => {
  let form = new FormData();
  form.append(
    "ktpFile",
    GeneralUtility.dataURLtoFile(data.ktpFile, data.ktpFileName),
    data.ktpFileName
  );
  form.append("ktpNik", data.ktpNik);
  form.append("merchantNib", data.merchantNib);
  if (data.npwpFile) {
    form.append(
      "npwpFile",
      GeneralUtility.dataURLtoFile(data.npwpFile, data.npwpFileName),
      data.npwpFileName
    );
  }
  if (data.npwpNumber) {
    form.append("npwpNumber", data.npwpNumber);
  }

  const url = BASE_API + Endpoint.checkLegalDoc;
  const response = await CallApi({
    url,
    method: "POST",
    data: form,
    type: "multipart",
  });
  return response;
};

const RegisterRepo = {
  getTnC,
  getProvince,
  getCity,
  getDistrict,
  getCodePhone,
  getBank,
  signUp,
  checkAccDetail,
  checkLegalDoc,
};

export default RegisterRepo;

import ProductModel from "../pages/product/model/ProductModel";
import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";

const BASE_API = process.env.REACT_APP_BASE_API;

const getProduct = async (data) => {
  const url = BASE_API + Endpoint.product_list;
  const response = await CallApi({ url, method: "POST", data });
  const dat = typeof response != "undefined" ? response.data : null;
  const dat2 = typeof dat != "undefined" && dat != null ? dat.data : [];
  const productList = typeof dat2 != "undefined" && dat2 != null ? dat2 : [];

  let array = [];
  for (let i = 0; i < productList.products.length; i++) {
    const element = productList.products[i];
    const edition =
      element.edition !== null && element.edition !== ""
        ? " - " + element.edition
        : "";
    const grams = element.gram !== null ? ", " + element.gram.name : "";
    const goldbarName = element.brand + edition + grams;
    const jewelryName = element.name + " - " + element.subCategory;
    const productName =
      element.category === "Gold Bar" ? goldbarName : jewelryName;
    const item = new ProductModel({
      id: element.id,
      productName: productName,
      viewCount: element.view,
      salesCount: element.sales,
      status: element.status,
      price: element.price,
      promoPrice: element.promoPrice,
      stock: element.stock,
      image: element.image,
      alt: "Tanam Emas",
      type: element.category,
      takenDown: element.status === -2 ? true : false,
      variant: element.variants,
      categoryId: element.categoryId,
      sku: element.sku !== null ? element.sku : "-",
    });
    array.push(item);
  }

  let list = {};
  list.error = response.error;
  list.message = response.message;
  list.page = dat2 ? dat2.page : 0;
  list.per_page = dat2 ? dat2.size : 0;
  list.total = dat2 ? dat2.totalSize : 0;
  list.total_pages = dat2 ? dat2.totalPage : 0;
  list.data = array;
  return list;
};

const getCategory = async () => {
  const url = BASE_API + Endpoint.product_category_list;
  const response = await CallApi({ url, method: "POST" });
  return response;
};

const addProduct = async (data) => {
  const url = BASE_API + Endpoint.product_add;
  const response = await CallApi({
    url,
    method: "POST",
    data,
    type: "multipart",
  });
  return response;
};

const editProduct = async (data) => {
  const url = BASE_API + Endpoint.product_edit;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const detailProduct = async (data) => {
  const url = BASE_API + Endpoint.product_detail;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const duplicateProduct = async (data) => {
  const url = BASE_API + Endpoint.product_duplicate;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const deleteProduct = async (data) => {
  const url = BASE_API + Endpoint.product_delete;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const editStatus = async (data) => {
  const url = BASE_API + Endpoint.edit_status;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const editVariantStatus = async (data) => {
  const url = BASE_API + Endpoint.edit_variant_status;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const editPrice = async (data) => {
  const url = BASE_API + Endpoint.edit_price;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const editVariantPrice = async (data) => {
  const url = BASE_API + Endpoint.edit_variant_price;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const editPromoPrice = async (data) => {
  const url = BASE_API + Endpoint.edit_promoprice;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const editVariantPromoPrice = async (data) => {
  const url = BASE_API + Endpoint.edit_variant_promoprice;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const editStock = async (data) => {
  const url = BASE_API + Endpoint.edit_stock;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const editVariantStock = async (data) => {
  const url = BASE_API + Endpoint.edit_variant_stock;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const getGram = async () => {
  const url = BASE_API + Endpoint.product_gram;
  const response = await CallApi({ url, method: "POST" });
  const data = typeof response != "undefined" ? response.data : null;
  const data2 = typeof data != "undefined" && data != null ? data.data : [];
  return data2;
};

const getCarat = async () => {
  const url = BASE_API + Endpoint.product_carat;
  const response = await CallApi({ url, method: "POST" });
  const data = response !== "undefined" ? response.data : null;
  const data2 = typeof data !== "undefined" && data !== null ? data.data : [];
  return data2;
};

const getVariant = async () => {
  const url = BASE_API + Endpoint.product_variant;
  const response = await CallApi({ url, method: "POST" });
  const data = response !== "undefined" ? response.data : null;
  const data2 = typeof data !== "undefined" && data !== null ? data.data : [];
  return data2;
};

const editPhoto = async (data) => {
  const url = BASE_API + Endpoint.edit_photo;
  const response = await CallApi({
    url,
    method: "POST",
    data,
    type: "multipart",
  });
  return response;
};

const addPhoto = async (data) => {
  const url = BASE_API + Endpoint.add_photo;
  const response = await CallApi({
    url,
    method: "POST",
    data,
    type: "multipart",
  });
  return response;
};

const deletePhoto = async (data) => {
  const url = BASE_API + Endpoint.delete_photo;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const ProductRepository = {
  getProduct,
  addProduct,
  editProduct,
  duplicateProduct,
  detailProduct,
  deleteProduct,
  editPrice,
  editStatus,
  editPromoPrice,
  editStock,
  getGram,
  editPhoto,
  addPhoto,
  deletePhoto,
  getCarat,
  getCategory,
  getVariant,
  editVariantStatus,
  editVariantPrice,
  editVariantPromoPrice,
  editVariantStock,
};

export default ProductRepository;

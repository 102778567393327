import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "register",
  initialState: {
    showPass: false,
    showConfirmPass: false,
    validateStatus: false,
    codephone: [],
    province: [],
    city: [],
    district: [],
    bank: [],
    accountDetails: {
      name: "",
      photo: "",
      photoName: "",
      email: "",
      phoneNumber: "",
      countryCodeId: "",
      address: "",
      provincesId: "",
      citiesId: "",
      districtsId: "",
      postalCode: "",
      ownerEmail: ""
    },
    bankDetails: {
      bankName: "",
      bankId: "",
      bankNumber: "",
    },
    legalDocuments: {
      ktpFile: "",
      ktpFileName: "",
      ktpNik: "",
      npwpFile: "",
      npwpFileName: "",
      npwpNumber: "",
      merchantNib: "",
    },
    security: {
      password: "",
      confirm_password: "",
    },
    termscoditions: {
      tnc: "",
      dng: "",
    },
    termscoditionsDesc: "",
    errorAccountDetails: {},
    errorApiCheck: {},
    errorBankDetails: {},
    errorLegalDocuments: {},
    errorSecurity: {},
    errorTermsconditions: {},
    page: "",
    loading: false,
    openZipCode: false,
    nextToBankDetails: false
  },
  reducers: {
    setPhoto: (state, action) => {
      state.photo = action.payload;
    },
    setCodePhone: (state, action) => {
      state.codephone = action.payload;
    },
    setProvince: (state, action) => {
      state.province = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setDistrict: (state, action) => {
      state.district = action.payload;
    },
    setBank: (state, action) => {
      state.bank = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setShowPass: (state, action) => {
      state.showPass = action.payload;
    },
    setShowConfirmPass: (state, action) => {
      state.showConfirmPass = action.payload;
    },
    setAccountDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    setBankDetails: (state, action) => {
      state.bankDetails = action.payload;
    },
    setLegalDocuments: (state, action) => {
      state.legalDocuments = action.payload;
    },
    setSecurity: (state, action) => {
      state.security = action.payload;
    },
    setTermsconditions: (state, action) => {
      state.termscoditions = action.payload;
    },
    setTermscoditionsDesc: (state, action) => {
      state.termscoditionsDesc = action.payload;
    },
    setErrorAccountDetails: (state, action) => {
      state.errorAccountDetails = action.payload;
    },
    setErrorBankDetails: (state, action) => {
      state.errorBankDetails = action.payload;
    },
    setErrorLegalDocuments: (state, action) => {
      state.errorLegalDocuments = action.payload;
    },
    setErrorSecurity: (state, action) => {
      state.errorSecurity = action.payload;
    },
    setErrorTermsconditions: (state, action) => {
      state.errorTermsconditions = action.payload;
    },
    setValidateStatus: (state, action) => {
      state.validateStatus = action.payload;
    },
    setErrorApiCheck: (state, action) => {
      state.errorApiCheck = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOpenZipCode: (state, action) => {
      state.openZipCode = action.payload;
    },
    setNextoToBankDetails: (state, action) => {
      state.nextToBankDetails = action.payload;
    }
  },
});

export const {
  setPhoto,
  setCodePhone,
  setProvince,
  setCity,
  setDistrict,
  setAccountDetails,
  setErrorAccountDetails,
  setBankDetails,
  setErrorBankDetails,
  setLegalDocuments,
  setErrorLegalDocuments,
  setPage,
  setBank,
  setErrorSecurity,
  setSecurity,
  setShowConfirmPass,
  setShowPass,
  setValidateStatus,
  setTermsconditions,
  setTermscoditionsDesc,
  setErrorTermsconditions,
  setErrorApiCheck,
  setLoading,
  setOpenZipCode,
  setNextoToBankDetails
} = reducerSlice.actions;
export default reducerSlice;

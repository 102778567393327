import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import FormInputCategory from "./FormInputCategory";
import Variant from "./Variant";
import InputText from "../../../../components/FormInput/InputText";
import InputTextCustom from "../../../../components/FormInput/InputTextCustom";
import ButtonGold from "../../../../components/ButtonGold/ButtonGold";
import ButtonWhite from "../../../../components/ButtonWhite/ButtonWhite";
import ProductRepository from "../../../../repositories/ProductRepository";
import VarOptionModel from "../../model/VarOption";
import VarCategoryModel from "../../model/VarCategory";
import GeneralUtility from "../../../../utils/general-utility";
import PopupUtility from "../../../../utils/popup-utility";
import { useNavigate } from "react-router-dom";
import RouteName from "../../../../services/routename";
import Cookies from "js-cookie";

const DEFAULT_DATA = {
  productName: "",
  caratId: "",
  lengthd: "",
  height: "",
  width: "",
  description: "",
  productSubCategoryId: "",
  weight: "",
  stock: "",
  price: "",
  promoPrice: "",
  containStone: false,
  certificationCode: "",
  caratWeight: "",
  preciousStoneWeight: "",
  colorGrade: "",
  clarityGrade: "",
  tabel: [],
  checkVariant: false,
  packagingWeight: ""
};

const JewelryForm = ({
  id,
  setImages,
  subCategory,
  jewelryId,
  type,
  images,
  onSucces,
  updateImg,
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState(DEFAULT_DATA);
  const [error, setError] = useState({});
  const [valid, setValid] = useState(false);
  const [tabel, setTabel] = useState([]);
  const [carat, setCarat] = useState([]);
  const [canBuyBack, setCanBuyBack] = useState(true);
  const [condition, setCondition] = useState(1);
  const [variantOption, setVariantOption] = useState([]);
  const [checkVariant, setCheckVariant] = useState(false);
  const [containStone, setContainStone] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const DUMMY_IMAGE = process.env.REACT_APP_ASSETS_IMAGE + "dummy-image.jpg";

  const canBuyBackCompability = Cookies.get("buybackCompability");
  const buybackCompability = canBuyBackCompability
    ? JSON.parse(canBuyBackCompability)
    : null;

  const getDetail = async () => {
    if (id) {
      const response = await ProductRepository.detailProduct({ id: id });
      let resp = response.data.data;
      let datas = {};
      let images = {};
      if (resp.images) {
        images.idPhoto1 = resp.images[0] ? resp.images[0].id : null;
        images.idPhoto2 = resp.images[1] ? resp.images[1].id : null;
        images.idPhoto3 = resp.images[2] ? resp.images[2].id : null;
        images.idPhoto4 = resp.images[3] ? resp.images[3].id : null;
        images.idPhoto5 = resp.images[4] ? resp.images[4].id : null;
        images.photo1 = resp.images[0] ? resp.images[0].image : "";
        images.photo2 = resp.images[1] ? resp.images[1].image : "";
        images.photo3 = resp.images[2] ? resp.images[2].image : "";
        images.photo4 = resp.images[3] ? resp.images[3].image : "";
        images.photo5 = resp.images[4] ? resp.images[4].image : "";
      }
      datas.id = resp.id;
      datas.productName = resp.name;
      datas.caratId = resp.carat !== null ? resp.carat.id : "";
      datas.lengthd = resp.length;
      datas.height = resp.height;
      datas.width = resp.width;
      datas.description = resp.description;
      datas.productSubCategoryId = resp.subCategoryId;
      datas.weight = resp.weight;
      datas.packagingWeight = resp.packagingWeight;
      datas.stock = resp.stock;
      datas.price = GeneralUtility.addCommas(resp.price);
      datas.promoPrice = GeneralUtility.addCommas(resp.promoPrice);
      datas.certificationCode =
        resp.preciousStoneDetail !== null
          ? resp.preciousStoneDetail.preciousStoneCertificationCode
          : "";
      datas.caratWeight =
        resp.preciousStoneDetail !== null
          ? resp.preciousStoneDetail.caratWeight
          : "";
      datas.preciousStoneWeight =
        resp.preciousStoneDetail !== null
          ? resp.preciousStoneDetail.preciousStoneWeight
          : "";
      datas.colorGrade =
        resp.preciousStoneDetail !== null
          ? resp.preciousStoneDetail.colorGrade
          : "";
      datas.clarityGrade =
        resp.preciousStoneDetail !== null
          ? resp.preciousStoneDetail.clarityGrade
          : "";
      datas.variantSelectedList = resp.variantSelectedList ?? [];
      // datas.containStone =
      //   resp.preciousStoneDetail.preciousStoneCertificationCode === ""
      //     ? false
      //     : true;
      // datas.checkVariant = resp.variants === null ? false : true;
      // datas.tabel = resp.variants;
      setCheckVariant(
        resp.variants === null
          ? false
          : resp.variants[0].enabled === true
            ? true
            : false
      );
      setContainStone(resp.preciousStoneDetail === null ? false : true);
      setTabel(resp.variants ?? []);
      setData(datas);
      setImages(images);
      setCondition(resp.productCondition);
      if (resp.canBuyback === null) {
        setCanBuyBack(false);
      } else {
        setCanBuyBack(resp.canBuyback);
      }
    } else {
      setData({
        productName: "",
        caratId: "",
        lengthd: "",
        height: "",
        width: "",
        description: "",
        productSubCategoryId: "",
        weight: "",
        stock: "",
        price: "",
        promoPrice: "",
        containStone: false,
        certificationCode: "",
        caratWeight: "",
        preciousStoneWeight: "",
        colorGrade: "",
        clarityGrade: "",
        tabel: [],
        checkVariant: false,
        packagingWeight: ""
      });

      if (buybackCompability && buybackCompability !== null) {
        setCanBuyBack(true);
      } else {
        setCanBuyBack(false);
      }
    }
  };

  const getCarat = async () => {
    const carat = await ProductRepository.getCarat();
    setCarat(carat ?? []);
  };

  const getVariantOption = async () => {
    const variant = await ProductRepository.getVariant();
    variant.forEach((element) => {
      let option = element.options.forEach((item) => {
        new VarOptionModel({ id: item.id, option: item.option });
      });
      new VarCategoryModel({
        id: element.id,
        variant: element.variant,
        options: option,
      });
    });
    setVariantOption(variant);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "lengthd" ||
      name === "width" ||
      name === "height" ||
      name === "stock"
    ) {
      setData({ ...data, [name]: GeneralUtility.removeNonNumeric(value) });
    } else if (name === "weight" || name === "packagingWeight") {
      setData({ ...data, [name]: GeneralUtility.sanitizeNumericInput(value) });
    } else if (name === "price" || name === "promoPrice") {
      setData({ ...data, [name]: GeneralUtility.addSeparator(value) });
    } else {
      if (name === "description" && value.length > 1000) return;

      setData({ ...data, [name]: value });
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.productName) {
      errors.productName = "Product Name is required!";
    }
    if (!values.productSubCategoryId) {
      errors.productSubCategoryId = "Jewellery category is required!";
    }

    if (!values.caratId) {
      errors.caratId = "Purity is required!";
    }

    if (!values.lengthd) {
      errors.lengthd = "Length is required!";
    } else if (parseInt(values.lengthd) === 0) {
      errors.lengthd = "Length cannot be zero!";
    }

    if (!values.width) {
      errors.width = "Width is required!";
    } else if (parseInt(values.width) === 0) {
      errors.width = "Width cannot be zero!";
    }

    if (!values.height) {
      errors.height = "Height is required!";
    } else if (parseInt(values.height) === 0) {
      errors.height = "Height cannot be zero!";
    }

    if (!checkVariant) {
      if (!values.stock) {
        errors.stock = "Stock is required!";
      }
      // else if (parseInt(values.stock) < 0) {
      //   errors.stock = "Stock cannot be zero!";
      // }

      if (!values.weight) {
        errors.weight = "Total weight is required!";
      } else if (parseFloat(values.weight) === 0) {
        errors.weight = "Total weight cannot be zero!";
      }

      if (!values.price) {
        errors.price = "Price is required!";
      } else if (parseFloat(values.price.replace(/,/g, "")) === 0) {
        errors.price = "Price cannot be zero!";
      }

      if (values.promoPrice) {
        let price = values.price ? parseInt(values.price.replace(/,/g, "")) : 0;
        let promoPrice = values.promoPrice
          ? parseInt(values.promoPrice.replace(/,/g, ""))
          : 0;

        if (promoPrice >= price) {
          errors.promoPrice =
            "Promotional Price must be less equal than the price";
        }
      }
    }

    if (!values.packagingWeight) {
      errors.packagingWeight = "Packaging Weight is required!";
    } else if (parseFloat(values.packagingWeight) === 0) {
      errors.packagingWeight = "Packaging Weight cannot be zero!";
    }

    setError(errors);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res;
    let form = new FormData();

    if (type === "add") {
      if (images["0"]?.photo1 && images["0"]?.photo1 !== "") {
        form.append(
          "images",
          GeneralUtility.dataURLtoFile(
            images["0"]?.photo1,
            images["0"]?.photo1name
          ),
          images["0"]?.photo1name
        );
      }
      if (images["1"]?.photo2 && images["1"]?.photo2 !== "") {
        form.append(
          "images",
          GeneralUtility.dataURLtoFile(
            images["1"]?.photo2,
            images["1"]?.photo2name
          ),
          images["1"]?.photo2name
        );
      }
      if (images["2"]?.photo3 && images["2"]?.photo3 !== "") {
        form.append(
          "images",
          GeneralUtility.dataURLtoFile(
            images["2"]?.photo3,
            images["2"]?.photo3name
          ),
          images["2"]?.photo3name
        );
      }
      if (images["3"]?.photo4 && images["3"]?.photo4 !== "") {
        form.append(
          "images",
          GeneralUtility.dataURLtoFile(
            images["3"]?.photo4,
            images["3"]?.photo4name
          ),
          images["3"]?.photo4name
        );
      }
      if (images["4"]?.photo5 && images["4"]?.photo5 !== "") {
        form.append(
          "images",
          GeneralUtility.dataURLtoFile(
            images["4"]?.photo5,
            images["4"]?.photo5name
          ),
          images["4"]?.photo5name
        );
      }

      form.append("name", data.productName);
      form.append("caratId", data.caratId);
      form.append("length", data.lengthd);
      form.append("height", data.height);
      form.append("width", data.width);
      form.append("description", data.description ? data.description : "");
      form.append("productCategoryId", jewelryId);
      form.append("productSubCategoryId", data.productSubCategoryId);
      form.append("productCondition", condition);
      form.append("packagingWeight", data.packagingWeight);

      if (buybackCompability) {
        form.append("canBuyback", canBuyBack);
      }

      if (!checkVariant) {
        form.append("weight", data.weight);
        form.append("stock", data.stock);
        form.append("price", GeneralUtility.decimalToInt(data.price));
        form.append("promoPrice", GeneralUtility.decimalToInt(data.promoPrice));
      } else {
        form.append("weight", 0);
        form.append("stock", 0);
        form.append("price", 0);
        form.append("promoPrice", 0);
      }

      if (containStone) {
        form.append(
          "preciousStoneDetail.preciousStoneCertificationCode",
          data.certificationCode ?? ""
        );
        // form.append("preciousStoneDetail.caratWeight", data.caratWeight ?? "");
        form.append("preciousStoneDetail.caratWeight", "");
        form.append(
          "preciousStoneDetail.preciousStoneWeight",
          data.preciousStoneWeight ?? ""
        );
        form.append("preciousStoneDetail.colorGrade", data.colorGrade ?? "");
        form.append(
          "preciousStoneDetail.clarityGrade",
          data.clarityGrade ?? ""
        );
      }
      // else {
      //   form.append("preciousStoneDetail.preciousStoneCertificationCode", "");
      //   form.append("preciousStoneDetail.caratWeight", "");
      //   form.append("preciousStoneDetail.preciousStoneWeight", "");
      //   form.append("preciousStoneDetail.colorGrade", "");
      //   form.append("preciousStoneDetail.clarityGrade", "");
      // }

      // ***
      // berikan kondisi ketika seluruh variant tidak memiliki main
      // maka set main pada variant pertama menjadi true
      // ***
      const allMainsFalse = tabel.every((item) => item.main === false);
      if (tabel.length !== 0 && allMainsFalse) {
        tabel[0].main = true;
      }

      if (checkVariant) {
        tabel.forEach((item, index) => {
          if (item.image && !item.image.includes(DUMMY_IMAGE)) {
            form.append(
              `productVariants[${index}].image`,
              GeneralUtility.dataURLtoFile(item.image, item.imageFilename),
              item.imageFilename
            );
          } else{
            form.append(
              `productVariants[${index}].image`,
              GeneralUtility.dataURLtoFile(images["0"]?.photo1,images["0"]?.photo1name),
              images["0"]?.photo1name
            );            
          }
          if (item.colorId) {
            form.append(`productVariants[${index}].colorId`, item.colorId);
          }

          if (item.sizeId) {
            form.append(`productVariants[${index}].sizeId`, item.sizeId);
          }

          form.append(`productVariants[${index}].stock`, item.stock);
          form.append(
            `productVariants[${index}].normalPrice`,
            item.normalPrice
          );
          form.append(
            `productVariants[${index}].promotionalPrice`,
            item.promotionalPrice
          );
          form.append(`productVariants[${index}].weight`, item.weight);
          form.append(`productVariants[${index}].status`, item.status);
          form.append(`productVariants[${index}].main`, item.main);
          form.append(`productVariants[${index}].action`, "enabled");
        });
      }

      setDisabled(true);
      setLoading(true);
      // for (let entry of form.entries()) {
      //   console.log(entry);
      // }
      // return
      res = await ProductRepository.addProduct(form);
    } else if (type === "edit") {
      form.append("name", data.productName);
      form.append("id", data.id);
      // form.append("name", data.productName);
      // form.append("caratId", data.caratId);
      form.append("length", data.lengthd);
      form.append("height", data.height);
      form.append("width", data.width);
      form.append("description", data.description ? data.description : "");
      form.append("packagingWeight", data.packagingWeight);
      // form.append("productCategoryId", jewelryId);
      // form.append("productSubCategoryId", data.productSubCategoryId);

      if (!checkVariant) {
        form.append("weight", data.weight);
        form.append("stock", data.stock);
        form.append("price", GeneralUtility.decimalToInt(data.price));
        form.append("promoPrice", GeneralUtility.decimalToInt(data.promoPrice));
      }
      // else {
      //   form.append("weight", 0);
      //   form.append("stock", 0);
      //   form.append("price", 0);
      //   form.append("promoPrice", 0);
      // }

      // if (containStone) {
      //   form.append(
      //     "preciousStoneDetail.preciousStoneCertificationCode",
      //     data.certificationCode ?? ""
      //   );
      //   form.append("preciousStoneDetail.caratWeight", data.caratWeight ?? "");
      //   form.append(
      //     "preciousStoneDetail.preciousStoneWeight",
      //     data.preciousStoneWeight ?? ""
      //   );
      //   form.append("preciousStoneDetail.colorGrade", data.colorGrade ?? "");
      //   form.append(
      //     "preciousStoneDetail.clarityGrade",
      //     data.clarityGrade ?? ""
      //   );
      // }

      if (updateImg) {
        updateImg.forEach((value, index) => {
          form.append(`images[${index}].id`, value.id);
          if (value.action === "update" || value.action === "add") {
            const file = GeneralUtility.dataURLtoFile(value.image, value.filename);
            form.append(
              `images[${index}].image`,
              file
            );
          }
          form.append(`images[${index}].action`, value.action);
        });
      }

      tabel.forEach((item, index) => {
        form.append(`variants[${index}].id`, item.id);

        // if (typeof item.image !== "string") {
        //   form.append(`variants[${index}].image`, item.image);
        // }
        if (item.colorId) {
          form.append(`variants[${index}].colorId`, item.colorId);
        }

        if (item.sizeId) {
          form.append(`variants[${index}].sizeId`, item.sizeId);
        }

        form.append(`variants[${index}].stock`, item.stock);
        form.append(`variants[${index}].normalPrice`, item.normalPrice);
        form.append(
          `variants[${index}].promotionalPrice`,
          item.promotionalPrice
        );
        form.append(`variants[${index}].weight`, item.weight);
        form.append(`variants[${index}].status`, item.status);
        form.append(`variants[${index}].main`, item.main);
        if (checkVariant) {
          form.append(`variants[${index}].action`, "enabled");
        } else {
          form.append(`variants[${index}].action`, "disabled");
        }
      });

      setDisabled(true);
      setLoading(true);
      res = await ProductRepository.editProduct(form);
    }

    setDisabled(false);
    setLoading(true);
    onSucces();
    if (!res.error) {
      setData({
        productName: "",
        caratId: "",
        lengthd: 0,
        height: 0,
        width: 0,
        description: "",
        productSubCategoryId: "",
        weight: 0,
        stock: 0,
        price: 0,
        promoPrice: 0,
        containStone: false,
        certificationCode: "",
        caratWeight: "",
        preciousStoneWeight: "",
        colorGrade: "",
        clarityGrade: "",
        tabel: [],
        checkVariant: false,
      });
      setCanBuyBack(true);

      if (type === "add") {
        PopupUtility.success("A new product has been successfully added.");
      } else {
        PopupUtility.success("Product has been successfully updated.");
      }
    } else {
      const message = `
        <p class="text-lg mb-3 font-extrabold">${res.message}</p>
        <p class="text-sm font-bold text-gray-400 tracking-wider">Trace Id : ${res.trace_id}</p>
      `;
      PopupUtility.responseValidate("Add Product Failed", message);
    }
  };

  function allIdPhotoNull(obj) {
    for (let key in obj) {
      if (key.startsWith('idPhoto') && obj[key] !== null) {
        return false;
      }
    }
    return true;
  }

  const checkProductImagesIsNull = () => {
    if (type === "add") {
      const imagesCount = Object.keys(images);
      setDisabled(imagesCount.length <= 0);
    }

    if (type === "edit") {
      const filteredData = updateImg.filter(item => item.action !== "delete");
      const isNull = allIdPhotoNull(images);
      if (filteredData.length <= 0 && isNull) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    if (valid === true) {
      validate(data);
    } else if (valid === false) {
      getCarat();
      getVariantOption();
      getDetail();
      setValid(true);
    }
  }, [data]);

  useEffect(() => {
    const errors = Object.keys(error);
    if (!checkVariant) {
      return setDisabled(errors.length > 0);
    }
    const numError = errors.filter(
      (item) => item !== "price" && item !== "stock"
    );

    const numTabel = tabel.length;
    setDisabled(numError.length > 0 || numTabel <= 0);
    checkProductImagesIsNull();
  }, [error, checkVariant, tabel]);


  useEffect(() => {
    checkProductImagesIsNull();
  }, [error]);

  useEffect(() => {
    checkProductImagesIsNull();
  }, [checkVariant]);

  useEffect(() => {
    checkProductImagesIsNull();
  }, []);

  useEffect(() => {
    checkProductImagesIsNull();
  }, [images, updateImg]);

  return (
    <>
      {/* Product Condition */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">
            {t("products.title_product_condition")}
          </div>
          <div className="pt-3 w-60">
            {t("products.subtitle_product_condition")}
          </div>
        </div>
        <div className="flex-1">
          <div className={`flex items-center`}>
            <input
              checked={condition === 1}
              onChange={(e) => {
                if (type === "add") {
                  setCondition(1);
                }
              }}
              id="new"
              type="radio"
              value=""
              name="productCondition"
              className={`w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary`}
              disabled={type === "edit"}
            />
            <label
              htmlFor="new"
              className={`ml-2 mr-5 text-sm font-medium text-gray-900 dark:text-gray-300`}>
              New
            </label>
            <input
              checked={condition === 0}
              onChange={(e) => {
                if (type === "add") {
                  setCondition(0);
                }
              }}
              id="used"
              type="radio"
              value=""
              name="productCondition"
              className={`w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary`}
              disabled={type === "edit"}
            />
            <label
              htmlFor="used"
              className={`ml-2 text-sm font-medium text-gray-900 dark:text-gray-300`}>
              Used
            </label>
          </div>
        </div>
      </div>

      {/* Product Name */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">
            {t("products.product_name")}
            <span className="text-gold-secondary">*</span>
          </div>

          <div className="pt-3 w-60">{t("products.condition_product")}</div>
        </div>
        <div className="flex-1">
          <InputText
            placeholder={t("products.input_product")}
            name={"productName"}
            value={data.productName}
            error={error.productName}
            onChange={(e) => {
              handleChange(e);
            }}
            maxLength={255}
            height={""}
            disabled={false}
          />
        </div>
      </div>

      {/* Category */}
      <FormInputCategory
        title={t("products.jewelry_category")}
        subtitle={t("products.condition_jewelry_category")}
        required={true}
        array={subCategory}
        onChange={(e) => {
          handleChange(e);
        }}
        valueCheckStone={containStone}
        valueCategory={data.productSubCategoryId}
        valueCertificationCode={data.certificationCode}
        valueCaratWeight={data.caratWeight}
        valueStoneWeight={data.preciousStoneWeight}
        valueColorGrade={data.colorGrade}
        valueClarityGrade={data.clarityGrade}
        errorCategory={error.productSubCategoryId}
        errorCertificationCode={error.certificationCode}
        errorCaratWeight={error.caratWeight}
        errorColorGrade={error.colorGrade}
        errorStoneWeight={error.gramDiamondWeight}
        errorClarityGrade={error.clarityGrade}
        setContainStone={setContainStone}
        disabled={type !== "add"}
      />

      {/* Carat */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">
            {t("products.carat")}
            <span className="text-gold-secondary">*</span>
          </div>

          <div className="pt-3 w-60">{t("products.condition_carat")}</div>
        </div>
        <div className="flex-1">
          <select
            className={`text-sm border rounded-xl w-full bg-white font-medium ${error.caratId ? "border-red-500" : "border-gray-primary"
              }`}
            name={"caratId"}
            value={data.caratId ? data.caratId : "default"}
            onChange={(e) => {
              handleChange(e);
            }}
            disabled={type !== "add" ? true : false}
          >
            <option value="default" disabled>
              {t("products.input_carat")}
            </option>
            {carat.map((item, index) => (
              <option value={item.id} key={index}>
                {item.name}
              </option>
            ))}
          </select>
          <p className="text-red-500">{error.caratId}</p>
        </div>
      </div>

      {/* Weight*/}
      {!checkVariant ? (
        <div className="flex flex-row gap-x-4 pt-8">
          <div className="w-72">
            <div className="font-bold">
              {t("products.jewel_weight")}
              <span className="text-gold-secondary">*</span>
            </div>

            <div className="pt-3 w-60">{t("products.condition_jewel_weight")}</div>
          </div>
          <div className="flex-1">
            <InputTextCustom
              placeholder={t("products.input_weight")}
              name="weight"
              value={data.weight}
              error={error.weight}
              onChange={(e) => {
                handleChange(e);
              }}
              maxLength={"20"}
              unit={"gr"}
              onFocus={(e) => {
                e.target.select();
              }}
            />
          </div>
        </div>
      ) : null}

      {/* Dimension */}
      <div className="flex w gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">
            {t("products.dimension")}
            <span className="text-gold-secondary">*</span>
          </div>

          <div className="pt-3 w-60">{t("products.condition_dimension")}</div>
        </div>
        <div className="flex-1">
          <div className="flex flex-row gap-x-5">
            {/* length */}
            <div className="flex-1">
              <p className="pl-3 pb-2 font-bold text-green-secondary">
                {t("products.length")}
              </p>
              <InputTextCustom
                placeholder={t("products.input_length")}
                name="lengthd"
                value={data.lengthd}
                error={error.lengthd}
                onFocus={(e) => {
                  e.target.select();
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
                maxLength={20}
                unit="cm"
              />
            </div>

            {/* width */}
            <div className="flex-1">
              <p className="pl-3 pb-2 font-bold text-green-secondary">
                {t("products.width")}
              </p>
              <InputTextCustom
                placeholder={t("products.input_width")}
                name="width"
                value={data.width}
                error={error.width}
                onFocus={(e) => {
                  e.target.select();
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
                maxLength={20}
                unit="cm"
              />
            </div>

            {/* height */}
            <div className="flex-1">
              <p className="pl-3 pb-2 font-bold text-green-secondary">
                {t("products.height")}
              </p>
              <InputTextCustom
                placeholder={t("products.input_height")}
                name="height"
                value={data.height}
                error={error.height}
                onFocus={(e) => {
                  e.target.select();
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
                maxLength={20}
                unit="cm"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Packging Weight */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">
            {t("products.packging_weight")}
            <span className="text-gold-secondary">*</span>
          </div>

          <div className="pt-3 w-60">{t("products.desc_packing_weight")}</div>
        </div>
        <div className="flex-1">
          <InputTextCustom
            placeholder={t("products.placeholder_packging_weight")}
            name="packagingWeight"
            value={data.packagingWeight}
            error={error.packagingWeight}
            onChange={(e) => {
              handleChange(e);
            }}
            maxLength={"20"}
            unit={"gr"}
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </div>
      </div>

      {/* Description */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">{t("products.description")}</div>
          <div className="pt-3 w-60">{t("products.condition_description")}</div>
        </div>
        <div className="flex-1">
          <textarea
            name={"description"}
            onChange={(e) => {
              handleChange(e);
            }}
            placeholder={t("products.input_description")}
            className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${error.description ? "border-red-500 border-1" : "border-0"
              }`}
            value={data.description}
            maxLength={1000}
            rows={3}
          />
          <p className="text-red-500">{error.description}</p>
        </div>
      </div>

      {/* Variant */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">{"Variant"}</div>

          <div className="pt-3 w-60">{t("products.condition_stock")}</div>
        </div>
        <div className="flex-1">
          {!checkVariant ? (
            <ButtonWhite
              disabled={type === "edit"}
              title={"ADD VARIANT"}
              onClick={(e) => {
                e.preventDefault();
                setCheckVariant(true);
                setTabel([]);
              }}
            />
          ) : (
            <>
              <ButtonWhite
                title={"DELETE VARIANT"}
                onClick={(e) => {
                  e.preventDefault();
                  setCheckVariant(false);
                }}
                disabled={type === "edit"}
              />

              <Variant
                variantOption={variantOption}
                tabel={tabel}
                setTabel={setTabel}
                type={type}
                image={type === "add" ? images["0"]?.photo1 : ""}
                imageFilename={type === "add" ? images["0"]?.photo1name : ""}
                setCheckVariant={setCheckVariant}
                variantSelectedList={data.variantSelectedList}
              />
            </>
          )}
        </div>
      </div>

      {/*  ======
        jika variant false maka tampil product stock & price
      ====== */}
      {!checkVariant ? (
        <>
          {/* Stock */}
          <div className="flex flex-row gap-x-4 pt-8">
            <div className="w-72">
              <div className="font-bold">{t("products.stock_product")}</div>
              <div className="pt-3 w-60">{t("products.condition_stock")}</div>
            </div>
            <div className="flex-1">
              <InputText
                placeholder={t("products.input_stock")}
                name={"stock"}
                value={data.stock}
                error={error.stock}
                onChange={(e) => {
                  handleChange(e);
                }}
                maxLength={20}
                height={""}
                onFocus={(e) => {
                  e.target.select();
                }}
              />
            </div>
          </div>

          {/* Price */}
          <div className="flex w gap-x-4 pt-8">
            <div className="w-72">
              <div className="font-bold">
                {t("products.price_product")}
                <span className="text-gold-secondary">*</span>
              </div>
              <div className="pt-3 w-60">{t("products.condition_price")}</div>
            </div>
            <div className="flex-1">
              <div className="flex flex-row gap-x-5">
                {/* price */}
                <div className="flex-1">
                  <p className="pl-3 pb-2 font-bold text-green-secondary">
                    {t("products.normal_price")}
                  </p>
                  <label className="relative block">
                    <span className="absolute inset-y-0 left-2.5 flex items-center">
                      <p className="font-bold text-green-secondary">Rp</p>
                    </span>
                    <input
                      type="text"
                      name="price"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      placeholder={t("products.input_price")}
                      className={`pl-10 rounded-xl w-full text-right text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${error.price ? "border-red-500 border-1" : "border-0"
                        }`}
                      value={data.price}
                      maxLength={100}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                    />
                  </label>
                  <p className="text-red-500">{error.price}</p>
                </div>

                {/* promotional price */}
                <div className="flex-1">
                  <p className="pl-3 pb-2 font-bold text-green-secondary">
                    {t("products.promotional_price")}
                  </p>
                  <label className="relative block">
                    <span className="absolute inset-y-0 left-2.5 flex items-center">
                      <p className="font-bold text-green-secondary">Rp</p>
                    </span>
                    <input
                      type="text"
                      name="promoPrice"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      placeholder={t("products.input_promotional")}
                      className={`pl-10 rounded-xl w-full text-right text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${error.promoPrice
                        ? "border-red-500 border-1"
                        : "border-0"
                        }`}
                      value={data.promoPrice}
                      maxLength={100}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                    />
                  </label>
                  <p className="text-red-500">{error.promoPrice}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* Buyback Avaibility */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">
            {t("products.title_buyback_availbility")}
          </div>
          <div className="pt-3 w-60">
            {t("products.subtitle_buyback_availbility")}
          </div>
        </div>
        <div className="flex-1">
          <div className={`flex items-center`}>
            <input
              checked={canBuyBack}
              onChange={(e) => {
                if (type === "add") {
                  setCanBuyBack(true);
                }
              }}
              id="available"
              type="radio"
              value=""
              name="canBuyBack"
              className={` ${!buybackCompability ? "cursor-not-allowed" : ""
                } w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary`}
              disabled={type === "edit" || !buybackCompability}
            />
            <label
              htmlFor="available"
              className={` ${!buybackCompability ? "cursor-not-allowed" : ""
                } mx-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
            >
              Available
            </label>
            <input
              checked={!canBuyBack}
              onChange={(e) => {
                if (type === "add") {
                  setCanBuyBack(false);
                }
              }}
              id="notAvailable"
              type="radio"
              value=""
              name="canBuyBack"
              className={` ${!buybackCompability ? "cursor-not-allowed" : ""
                } w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary`}
              disabled={type === "edit" || !buybackCompability}
            />
            <label
              htmlFor="notAvailable"
              className={` ${!buybackCompability ? "cursor-not-allowed" : ""
                } ml-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
            >
              Not Available
            </label>
          </div>
        </div>
      </div>

      <div className="items-stretch gap-x-2 py-4 flex justify-end">
        {type === "add" ? (
          <>
            <ButtonWhite
              title={t("products.cancel").toUpperCase()}
              onClick={(e) => {
                navigate(RouteName.products, { replace: true });
              }}
            />
            <ButtonGold
              title={t("products.add").toUpperCase()}
              disabled={disabled}
              onClick={(e) => {
                handleSubmit(e);
              }}
              loading={isLoading}
            />
          </>
        ) : (
          <>
            <ButtonWhite
              title={t("products.cancel").toUpperCase()}
              onClick={(e) => {
                navigate(RouteName.products, { replace: true });
              }}
            />
            <ButtonGold
              title={t("products.save").toUpperCase()}
              disabled={disabled}
              onClick={(e) => {
                handleSubmit(e);
              }}
              loading={isLoading}
            />
          </>
        )}
      </div>
    </>
  );
};

export default JewelryForm;

import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import UploadImage from "../../../../components/UploadImage/UploadImage";
import RegisterRepo from "../../../../repositories/RegisterRepo";
import Action from "../redux/Action";
import {
setAccountDetails,
setValidateStatus,
setErrorApiCheck,
setOpenZipCode,
setNextoToBankDetails,
} from "../redux/Reducer";
import GeneralUtility from "../../../../utils/general-utility";
import Select from "react-select";
import PopupConfirmation from "../../../../components/PopupConfirmation/PopupConfirmation";

const AccountDetails = () => {
const t = useTranslation();
const dispatch = useDispatch();
const province = useSelector((state) => state.reducer.province);
const city = useSelector((state) => state.reducer.city);
const district = useSelector((state) => state.reducer.district);
const codephone = useSelector((state) => state.reducer.codephone);
const accountDetails = useSelector((state) => state.reducer.accountDetails);
const errorAccountDetails = useSelector((state) => state.reducer.errorAccountDetails);
const errorApiCheck = useSelector((state) => state.reducer.errorApiCheck);
const validateStatus = useSelector((state) => state.reducer.validateStatus);
const openZipCode = useSelector((state) => state.reducer.openZipCode);
const nextToBankDetails = useSelector((state) => state.reducer.nextToBankDetails);

const handleChange = (e) => {
  const { name, value } = e.target;
  if (name == "phoneNumber" || name == "postalCode") {
    dispatch(
      setAccountDetails({
        ...accountDetails,
        [name]: GeneralUtility.removeNonNumeric(value),
      })
    );
  } else {
    dispatch(setAccountDetails({ ...accountDetails, [name]: value }));
  }
};

const handleSubmit = async (e) => {
  e.preventDefault();
  const isValid = Action.validateStep1(dispatch, accountDetails);
  if (Object.keys(isValid).length === 0) {
    if (nextToBankDetails) {
      return Action.getPage(dispatch, "bankdetails");
    }

    const res = await RegisterRepo.checkAccDetail(accountDetails);
    if (res.error) {
      return Action.handleOpenZipCode(dispatch);
    } 
    
      return Action.getPage(dispatch, "bankdetails");
  }
};

useEffect(() => {
  if (validateStatus === false) {
    Action.getProvince(dispatch);
    Action.getCodePhone(dispatch);
    dispatch(setValidateStatus(true));
  } else if (validateStatus === true) {
    Action.validateStep1(dispatch, accountDetails);
  }
}, [accountDetails]);

return (
  <>
    <form>
      <div className="flex flex-col gap-y-4 md:h-[30em] overflow-y-auto">
        {/* merchant photo */}
        <div className="flex flex-row gap-x-3">
          <div className="w-48">
            <div className="text-md font-bold">
              {t("register.merchant_photo")}
            </div>
            <div className="opacity-40">
              {t("general.support_files")} jpeg,jpg,png
            </div>
            <div className="opacity-40">{t("general.max_size")} 1Mb</div>
          </div>
          <div className="flex-1">
            <UploadImage
              img={accountDetails.photo}
              aspectRatio={1 / 1}
              name={"photo"}
              width={40}
              height={40}
              position={"col"}
              onClick={(photo, name) =>
                dispatch(
                  setAccountDetails({
                    ...accountDetails,
                    photo: photo,
                    photoName: name,
                  })
                )
              }
              onDelete={(imageRef) => {
                imageRef.current.value = null;
              }}
            />
            <p className="text-red-500">{errorAccountDetails.photo}</p>
          </div>
        </div>
        {/* merchant name */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-48">
            <div
              className={`text-md font-bold ${errorAccountDetails.name ? "pb-4" : " "
                }`}
            >
              {t("register.merchant_name")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="name"
              onChange={handleChange}
              placeholder={t("register.input_merchant_name")}
              className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errorAccountDetails.name
                ? "border-red-500 border-1"
                : "border-0"
                }`}
              defaultValue={accountDetails.name}
            />
            <p className="text-red-500">{errorAccountDetails.name}</p>
          </div>
        </div>
        {/* email */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-48">
            <div
              className={`text-md font-bold ${errorAccountDetails.ownerEmail ? "pb-4" : " "
                }`}>
              {t("register.email")}
              <span className="text-gold-secondary">*</span>
            </div>
            <div className="opacity-40">{t("register.email_address_desc")}</div>
          </div>
          <div className="flex-1">
            <input
              type="email"
              name="ownerEmail"
              onChange={handleChange}
              placeholder={t("register.input_email")}
              className={`rounded-xl w-full text-sm font-bold bg-white 
placeholder:text-green-secondary placeholder:font-medium 
${errorAccountDetails.ownerEmail
                  ? "border-red-500 border-1"
                  : "border-0"
                }`}
              defaultValue={accountDetails.ownerEmail}
            />
            <p className="text-red-500">{errorAccountDetails.ownerEmail}</p>
            <p className="text-red-500">{errorApiCheck.ownerEmail}</p>
          </div>
        </div>
        {/* merchant email */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-48">
            <div
              className={`text-md font-bold ${errorAccountDetails.email ? "pb-4" : " "
                }`}>
              {t("register.merchant_email")}
              <span className="text-gold-secondary">*</span>
            </div>
            <div className="opacity-40">{t("register.merchant_email_desc")}</div>
          </div>
          <div className="flex-1">
            <input
              type="email"
              name="email"
              onChange={handleChange}
              placeholder={t("register.input_emailMerchant")}
              className={`rounded-xl w-full text-sm font-bold bg-white 
placeholder:text-green-secondary placeholder:font-medium 
${errorAccountDetails.email
                  ? "border-red-500 border-1"
                  : "border-0"
                }`}
              defaultValue={accountDetails.email}
            />
            <p className="text-red-500">{errorAccountDetails.email}</p>
            <p className="text-red-500">{errorApiCheck.email}</p>
          </div>
        </div>
        {/* phone number */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-48">
            <div
              className={`text-md font-bold ${errorAccountDetails.phoneNumber ||
                errorAccountDetails.countryCodeId
                ? "pb-4"
                : " "
                }`}
            >
              {t("register.phone_no")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex flex-row items-center gap-x-2">
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: errorAccountDetails.phoneNumber
                      ? "red"
                      : "white",
                  }),
                }}
                className="text-sm border w-40 bg-white font-medium"
                defaultValue={accountDetails.countryCodeId}
                options={codephone}
                name="countryCodeId"
                onChange={(item) => {
                  handleChange({
                    target: { name: "countryCodeId", value: item.value },
                  });
                }}
              />
              <input
                type="text"
                name="phoneNumber"
                onChange={handleChange}
                placeholder={t("register.input_phone_no")}
                className={`w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errorAccountDetails.phoneNumber
                  ? "border-red-500 border-1"
                  : "border-0"
                  }`}
                style={{ borderRadius: "5px" }}
                value={accountDetails.phoneNumber}
              />
            </div>
            <p className="text-red-500">{errorAccountDetails.phoneNumber}</p>
            <p className="text-red-500">{errorApiCheck.phoneNumber}</p>
          </div>
        </div>
        {/* address */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-48">
            <div
              className={`text-md font-bold ${errorAccountDetails.address ? "pb-4" : " "
                }`}
            >
              {t("register.address")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="address"
              onChange={handleChange}
              placeholder={t("register.input_address")}
              className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errorAccountDetails.address
                ? "border-red-500 border-1"
                : "border-0"
                }`}
              defaultValue={accountDetails.address}
            />
            <p className="text-red-500">{errorAccountDetails.address}</p>
          </div>
        </div>
        {/* province */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-48">
            <div
              className={`text-md font-bold ${errorAccountDetails.provincesId ? "pb-4" : " "
                }`}
            >
              {t("register.province")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <select
              className={`text-sm border rounded-xl w-full bg-white font-medium ${errorAccountDetails.provincesId
                ? "border-red-500"
                : "border-gray-primary"
                }`}
              name="provincesId"
              defaultValue="default"
              onChange={(e) => {
                Action.getCity(dispatch, e.target.value);
                handleChange(e);
              }}
            >
              <option value="default" disabled>
                {t("register.select_province")}
              </option>
              {province.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
            <p className="text-red-500">{errorAccountDetails.provincesId}</p>
          </div>
        </div>
        {/* city */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-48">
            <div
              className={`text-md font-bold ${errorAccountDetails.citiesId ? "pb-4" : " "
                }`}
            >
              {t("register.city")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <select
              className={`text-sm border rounded-xl w-full bg-white font-medium ${errorAccountDetails.citiesId
                ? "border-red-500"
                : "border-gray-primary"
                }`}
              name="citiesId"
              defaultValue="default"
              onChange={(e) => {
                Action.getDistrict(dispatch, e.target.value);
                handleChange(e);
              }}
            >
              <option value="default" disabled>
                {t("register.select_city")}
              </option>
              {city.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
            <p className="text-red-500">{errorAccountDetails.citiesId}</p>
          </div>
        </div>
        {/* district */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-48">
            <div
              className={`text-md font-bold ${errorAccountDetails.districtsId ? "pb-4" : " "
                }`}
            >
              {t("register.district")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <select
              className={`text-sm border rounded-xl w-full bg-white font-medium ${errorAccountDetails.districtsId
                ? "border-red-500"
                : "border-gray-primary"
                }`}
              name="districtsId"
              defaultValue="default"
              onChange={handleChange}
            >
              <option value="default" disabled>
                {t("register.select_district")}
              </option>
              {district.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
            <p className="text-red-500">{errorAccountDetails.districtsId}</p>
          </div>
        </div>
        {/* postalcode */}
        <div className="flex flex-row items-center gap-x-3 pb-2">
          <div className="w-48">
            <div
              className={`text-md font-bold ${errorAccountDetails.postalCode ? "pb-4" : " "
                }`}
            >
              {t("register.postalcode")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="postalCode"
              onChange={handleChange}
              placeholder={t("register.input_postalcode")}
              className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errorAccountDetails.postalCode
                ? "border-red-500 border-1"
                : "border-0"
                }`}
              value={accountDetails.postalCode}
            />
            <p className="text-red-500">{errorAccountDetails.postalCode}</p>
            <p className="text-red-500">{errorApiCheck.postalCode}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse gap-x-2 pt-5">
        <button
          disabled={
            Object.keys(errorAccountDetails).length === 0 ? false : true
          }
          onClick={handleSubmit}
          className="button w-36 hover:bg-gray-primary text-white border-2 hover:text-green-quaternary border-gold-secondary text-xs tracking-widest bg-gold-secondary font-bold py-3 rounded-xl">
          {t("register.next").toUpperCase()}
        </button>
      </div>
    </form>
    {openZipCode && (
      <PopupConfirmation
        title={t("login.title_zipcode")}
        subtitle={t("login.subtitle_zipcode")}
        onCancel={() => {
          Action.handleCancelZipCode(dispatch);
        }}
        onDelete={() => {
          Action.handleNextToBankDetails(dispatch);
        }}
      />
    )}
  </>
);
};

export default AccountDetails;

import React, { useState } from 'react';
import { useTranslation } from 'react-multi-lang';

const TicketCancellationHistory = ({ item }) => {
    const t = useTranslation();
    const [tooltipVisible, setTooltipVisible] = useState(false);
    return (
        <div className="bg-white rounded-xl m-2">
            <div className="sm:text-sm flex flex-row p-5">
                <div className="flex-1">
                    <span className='inline-block text-gold-secondary font-bold'>{item.titleTicket}</span>
                    <span className='inline-block mx-2'>/</span>
                    {item.date}
                </div>
            </div>
            <hr />
            {/* header */}
            <div className="p-5">
                <div className="text-md font-bold">
                    {t("balance.cancellation")}
                </div>
            </div>
            <hr />
            {/* body */}
            <div className="p-5">
                {/* <div className='mb-3'>
                    <p className='font-bold'>{t("management.customer_name")}</p>
                    <span className="text-green-600 font-bold">
                        {item.customerName}
                    </span>
                </div> */}
                <div>
                    <p className='font-bold'>{t("balance.cancellation_fee")}</p>
                    <span className="text-red-600 font-medium">
                        -Rp{item.cancellationFee}
                    </span>
                </div>
            </div>
            {/* footer */}
            <div className="flex px-5 pb-5">
                <div className="flex items-center">
                    <span className="inline-block mr-2">{t("balance.total_charges")}</span>
                    <div className="relative flex items-center">
                        <img
                            onClick={() => setTooltipVisible(!tooltipVisible)}
                            src={process.env.REACT_APP_ASSETS_IMAGE + "information-outline.png"}
                            alt=""
                            className="w-4 h-4 cursor-pointer"
                        />
                        {tooltipVisible && (
                            <div className="absolute bg-white text-black p-2 rounded-md left-6 w-[260px] shadow-xl">
                                <div className="flex items-center  w-full justify-between mb-1">
                                    <span className="inline-block mr-4">{t("balance.cancellation_per_item")}</span>
                                    <span className="inline-block font-semibold">Rp{item.cancellationFeePerItem}</span>
                                </div>
                                <div className="flex items-center w-full justify-between">
                                    <span className="inline-block mr-4">{t("balance.cancellation_third_party")}</span>
                                    <span className="inline-block font-semibold">Rp{item.thirdPartyFee}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-1 text-right text-md font-bold text-red-600">
                    -Rp{item.cancellationFee}
                </div>
            </div>
        </div>
    );
};

export default TicketCancellationHistory;
import React from 'react';
import { useTranslation } from 'react-multi-lang';

const TicketSalesHistory = ({ item }) => {
    const t = useTranslation();
    let statusName;
    let statusColor;
    let statusTextColor;
    switch (item.status) {
        case "COMPLETED":
            statusName = t("general.completed");
            statusColor = "bg-[#A7FFA1]";
            statusTextColor = "text-[#047100]";
            break;
        case "CANCELED":
            statusName = t("general.cancelled");
            statusColor = "bg-[#FF9191]";
            statusTextColor = "text-white";
            break;
        case "PENDING":
            statusName = t("general.pending");
            statusColor = "bg-[#FCB769]";
            statusTextColor = "text-white";
            break;
        default:
            statusName = "";
            statusColor = "";
            statusTextColor = "";
            break;
    }
    return (
        <div className="bg-white rounded-xl m-2">
            <div className="sm:text-sm flex flex-row p-5">
                <div className="flex-1">
                    <span className='inline-block text-gold-secondary font-bold'>{item.titleTicket}</span>
                    <span className='inline-block mx-2'>/</span>
                    {item.date}
                </div>
                <div>
                    <span className={`px-2 py-1 ${statusColor} ${statusTextColor} rounded-md`}>
                        {statusName}
                    </span>
                </div>
            </div>
            <hr />
            {/* header */}
            <div className="p-5">
                <div className="text-md font-bold">
                    {t("balance.received")}
                </div>
            </div>
            <hr />
            {/* body */}
            <div className="p-5">
                {/* <div className='mb-3'>
                    <p className='font-bold'>{t("management.customer_name")}</p>
                    <span className="text-green-600 font-bold">
                        {item.customerName}
                    </span>
                </div> */}
                <div className='mb-3'>
                    <p className='font-bold'>{t("balance.commission")}</p>
                    <span className="text-red-600 font-medium">
                        -Rp{item.productCommission}
                    </span>
                </div>
                <div className='mb-3'>
                    <p className='font-bold'>{t("balance.shipping_const")}</p>
                    <span className="text-red-600 font-medium">
                        -Rp{item.shipping}
                    </span>
                </div>
                <div className='mb-3'>
                    <p className='font-bold'>{t("balance.insurance_fee")}</p>
                    <span className="text-red-600 font-medium">
                        -Rp{item.insurance}
                    </span>
                </div>
                <div className='mb-3'>
                    <p className='font-bold'>{t("balance.admin_application_fee")}</p>
                    <span className="text-red-600 font-medium">
                        -Rp{item.adminApplicationFee}
                    </span>
                </div>
                <div className='mb-3'>
                    <p className='font-bold'>{t("balance.transaction_service_fee")}</p>
                    <span className="text-red-600 font-medium">
                        -Rp{item.transactionServiceFee}
                    </span>
                </div>
                {item.totalPricePromo !== "0" ? (
                    <div className='mb-3'>
                        <p className='font-bold'>{t("balance.productPromo")}</p>
                        <span className="text-red-600 font-medium">
                            -Rp{item.totalPricePromo}
                        </span>
                    </div>
                ) : ""}
                {item.totalShippingPromo !== "0" ? (
                    <div className='mb-3'>
                        <p className='font-bold'>{t("balance.shippingPromo")}</p>
                        <span className="text-red-600 font-medium">
                            -Rp{item.totalShippingPromo}
                        </span>
                    </div>
                ) : ""}
                {item.totalAdminPromo !== "0" ? (
                    <div className='mb-3'>
                        <p className='font-bold'>{t("balance.productAdminPromo")}</p>
                        <span className="text-red-600 font-medium">
                            -Rp{item.totalAdminPromo}
                        </span>
                    </div>
                ) : ""}
            </div>
            {/* footer */}
            <div className="flex px-5 pb-5">
                <div>{t("balance.received")}</div>
                <div className="flex-1 text-right text-md font-bold">
                    Rp{item.balanceReceived}
                </div>
            </div>
        </div>
    );
};

export default TicketSalesHistory;
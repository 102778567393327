import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { NavLink } from "react-router-dom";
import RouteName from "../../../services/routename";
import UseAuth from "../Auth";

const NewPassword = () => {
  const [security, setSecurity] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [errorSecurity, setErrorSecurity] = useState("");
  const [showpass, setShowpass] = useState(false);
  const [showconfirmpass, setShowconfirmpass] = useState(false);
  const t = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSecurity({ ...security, [name]: value });
  };

  const validate = (values) => {
    const errors = {};

    if (values.newPassword.length < 8) {
      errors.char = "Password must at least 8 number!";
    }
    if (values.newPassword.search(/[0-9]/) < 0) {
      errors.number = "Password must contain at least one number!";
    }
    if (values.newPassword.search(/[a-z]/) < 0) {
      errors.lowercase = "Password must contain at least one lowercase!";
    }
    if (values.newPassword.search(/[A-Z]/) < 0) {
      errors.uppercase = "Password must contain at least one uppercase!";
    }

    if (values.confirmNewPassword !== values.newPassword) {
      errors.confirm_password = "Password doesnt match!";
    }

    setErrorSecurity(errors);
    return errors;
  };

  const handleNewpass = async (e) => {
    e.preventDefault();
    const isValid = validate(security);
    if (Object.keys(isValid).length === 0) {
      const token = new URLSearchParams(window.location.search).get("token"); //"hdAE8UrhUSAYoNo5WTG4LKgFGRdR6Wf4FD0M";
      const data = {
        token: token,
        newPassword: security.newPassword,
        confirmNewPassword: security.confirmNewPassword,
      };
      UseAuth.resetPassword(data, "web");
    }
  };

  useEffect(() => {
    validate(security);
  }, [security]);

  return (
    <div className="section py-8 px-6 lg:mx-20 md:mx-28">
      <NavLink to={RouteName.login} className="">
        <img
          src={process.env.REACT_APP_ASSETS_IMAGE + "back-icon-auth.svg"}
          alt="back"
        />
      </NavLink>
      <form>
        <h2 className="txt-green1 font-bold pl-2 pt-4">
          {t("newpassword.title")}
        </h2>
        <h3 className="font-medium pl-2 pt-2">{t("newpassword.subtitle")}</h3>

        {/* Password */}
        <label className="relative block mt-6">
          <span className="absolute inset-y-0 left-1.5 flex items-center pl-2">
            <img
              className="h-3.5 w-5 fill-slate-300"
              src={process.env.REACT_APP_ASSETS_IMAGE + "password-icon.svg"}
              alt="username-icon"
            />
          </span>
          <input
            type={`${showpass ? "text" : "password"}`}
            placeholder={t("newpassword.new_pass")}
            name="newPassword"
            className="px-3 py-3 pl-10 pr-3 bg-white border shadow-sm placeholder:text-slate-400 block w-full border-slate-300 rounded-lg focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm txt-green1"
            onChange={handleChange}
          />
          <span
            className="absolute inset-y-0 right-1.5 items-center"
            onClick={() => setShowpass(!showpass)}
          >
            <img
              className="h-full w-7 cursor-pointer"
              src={
                process.env.REACT_APP_ASSETS_IMAGE +
                "eye-" +
                (showpass ? "hide" : "show") +
                ".svg"
              }
              alt="eye-hide"
            />
          </span>
        </label>
        {/* <p className="text-red-500">{formErrors.password}</p> */}

        {/* Confirm Password */}
        <label className="relative block mt-6">
          <span className="absolute inset-y-0 left-1.5 flex items-center pl-2">
            <img
              className="h-3.5 w-5 fill-slate-300"
              src={process.env.REACT_APP_ASSETS_IMAGE + "password-icon.svg"}
              alt="username-icon"
            />
          </span>
          <input
            type={`${showconfirmpass ? "text" : "password"}`}
            placeholder={t("newpassword.confirm_pass")}
            name="confirmNewPassword"
            className="px-3 py-3 pl-10 pr-3 bg-white border shadow-sm placeholder:text-slate-400 block w-full border-slate-300 rounded-lg focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm txt-green1"
            onChange={handleChange}
          />
          <span
            className="absolute inset-y-0 right-1.5 items-center"
            onClick={() => setShowconfirmpass(!showconfirmpass)}
          >
            <img
              className="h-full w-7 cursor-pointer"
              src={
                process.env.REACT_APP_ASSETS_IMAGE +
                "eye-" +
                (showconfirmpass ? "hide" : "show") +
                ".svg"
              }
              alt="eye-hide"
            />
          </span>
        </label>
        {/* <p className="text-red-500">{formErrors.confirmpass}</p> */}

        {/* Validation */}
        <div className="font-medium py-5 text-center">
          <p className="font-bold text-md">{t("newpassword.rules")}</p>
          <div className="flex flex-row gap-x-3 pt-2">
            <div className="basis-1/4 flex flex-col text-center">
              <img
                className="h-16"
                src={
                  process.env.REACT_APP_ASSETS_IMAGE +
                  `${
                    errorSecurity.lowercase
                      ? "lowercase-inactive.svg"
                      : "lowercase.svg"
                  }`
                }
                alt="lowercase"
              />
              <p>{t("newpassword.lowercase")}</p>
            </div>
            <div className="basis-1/4 flex flex-col text-center">
              <img
                className="h-16"
                src={
                  process.env.REACT_APP_ASSETS_IMAGE +
                  `${
                    errorSecurity.uppercase
                      ? "uppercase-inactive.svg"
                      : "uppercase.svg"
                  }`
                }
                alt="uppercase"
              />
              <p>{t("newpassword.uppercase")}</p>
            </div>
            <div className="basis-1/4 flex flex-col text-center">
              <img
                className="h-16"
                src={
                  process.env.REACT_APP_ASSETS_IMAGE +
                  `${
                    errorSecurity.number ? "number-inactive.svg" : "number.svg"
                  }`
                }
                alt="number"
              />
              <p>{t("newpassword.number")}</p>
            </div>
            <div className="basis-1/4 flex flex-col text-center">
              <img
                className="h-16"
                src={
                  process.env.REACT_APP_ASSETS_IMAGE +
                  `${errorSecurity.char ? "char-inactive.svg" : "char.svg"}`
                }
                alt="char"
              />
              <p>{t("newpassword.char")}</p>
            </div>
          </div>
        </div>

        <div className="pt-2 text-right">
          <button
            onClick={handleNewpass}
            className="button min-w-fit hover:bg-gold-secondary bg-green-tertiary text-green-white text-sm font-bold py-3 px-16 rounded-lg"
          >
            {t("newpassword.button").toUpperCase()}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewPassword;

import React, { useState } from "react";
import { useTranslation } from "react-multi-lang";

const Dropdown = ({ status, onEdit, onDuplicate, onDelete }) => {
  const [show, setShow] = useState(false);
  const t = useTranslation();
  return (
    <div
      className="w-36 py-3 px-2 block shadow-sm border border-gray-primary rounded-xl bg-gray-primary duration-700 cursor-pointer"
      onClick={() => {
        setShow(!show);
      }}
    >
      <div className="relative">
        <div className="flex flex-row items-center text-left">
          <p className="flex-1 font-md">Actions</p>
          <div className={`text-right ${!show && "rotate-180"} duration-700 `}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.219"
              height="6.17"
              viewBox="0 0 10.219 6.17"
            >
              <g id="Arrow" transform="translate(1.061 1.061)">
                <line
                  id="Line_9"
                  data-name="Line 9"
                  x1="4.049"
                  y1="4.049"
                  transform="translate(8.097) rotate(90)"
                  fill="none"
                  stroke="#5d7d73"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
                <line
                  id="Line_10"
                  data-name="Line 10"
                  x1="4.049"
                  y2="4.049"
                  transform="translate(4.049) rotate(90)"
                  fill="none"
                  stroke="#5d7d73"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </g>
            </svg>
          </div>
        </div>
        {show ? (
          <div className="absolute mt-5 -right-2.5 section-grey b-0 w-36 z-10 shadow-md border border-gray-primary duration-700">
            <div
              className="flex flex-row items-center gap-x-1 py-2 px-2"
              onClick={() => {
                onEdit();
              }}
            >
              <img
                className="h-7 w-7"
                src={process.env.REACT_APP_ASSETS_IMAGE + "edit-icon.svg"}
                alt="edit"
              />
              <span>{t("products.edit")}</span>
            </div>
            <hr />
            {status ? null : (
              <>
                <div
                  className="flex flex-row items-center gap-x-1 py-2 px-2"
                  onClick={() => {
                    onDuplicate();
                  }}
                >
                  <img
                    className="h-4 w-4 mx-2 my-1"
                    src={process.env.REACT_APP_ASSETS_IMAGE + "icon-duplicate-product.png"}
                    alt="duplicate"
                  />
                  <span>{t("products.duplicate")}</span>
                </div>
                <hr />
              </>
            )}

            <div
              className="flex flex-row items-center gap-x-1 py-2 px-2"
              onClick={() => {
                onDelete();
              }}
            >
              <img
                className="h-4 w-4 mx-2 my-1"
                src={process.env.REACT_APP_ASSETS_IMAGE + "icon-delete-product.png"}
                alt="delete"
              />
              <span>{t("products.delete")}</span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Dropdown;

import { useState } from "react";
import { useTranslation } from "react-multi-lang";
import OrderInfo from "./OrderInfo";
import OrderProduct from "./OrderProduct";
import GeneralUtility from "../../../../utils/general-utility";

const OrderItem = ({ item, showInvoice, showTracking, setPickUp, getResi }) => {
  const t = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  let statusName;
  let statusColor;
  let statusTextColor;
  switch (item.status) {
    case "PAID":
      statusName = t("orders.new_order");
      statusColor = "bg-gray-300";
      statusTextColor = "text-white";
      break;
    case "ON_DELIVERY":
      statusName = t("orders.on_shipment");
      statusColor = "bg-[#FFB8EC]";
      statusTextColor = "text-[#B52BB5]";
      break;
    case "COMPLETED":
      statusName = t("general.completed");
      statusColor = "bg-[#D0FFCC]";
      statusTextColor = "text-[#6AC167]";
      break;
    case "CANCELED":
      statusName = t("general.cancelled");
      statusColor = "bg-[#FF9191]";
      statusTextColor = "text-white";
      break;
    case "RECEIVED":
      statusName = t("orders.received");
      statusColor = "bg-[#FCB769]";
      statusTextColor = "text-white";
      break;
    case "COMPLAINED":
      statusName = t("orders.complained");
      statusColor = "bg-[#FCB769]";
      statusTextColor = "text-white";
      break;
    default:
      statusName = "";
      statusColor = "";
      statusTextColor = "";
      break;
  }

  return (
    <div className="my-3 p-3 section-white">
      <div className="flex w-full justify-between items-center">
        <div className="sm:text-sm">
          <span className={`p-1 ${statusColor} ${statusTextColor} rounded-lg`}>
            {statusName}
          </span>{" "}
          /{" "}
          <button
            className="text-gold-secondary font-semibold"
            onClick={showInvoice}
          >
            {item.invoice}
          </button>{" "}
          /{" "}
          <span>
            {item.customerName} / {item.dateCreated}
          </span>
        </div>
        <div>
          {item.status === "PAID" || item.status === "ON_DELIVERY" ? (
            <button
              className="button hover:bg-gray-primary hover:text-green-secondary text-xs tracking-widest font-bold text-gray-primary border-2 border-green-secondary bg-green-secondary border-opacity-40 rounded-xl p-1 px-3"
              onClick={() => {
                if (item.status === "PAID") {
                  // set pickup
                  setPickUp();
                } else if (item.status === "ON_DELIVERY") {
                  // show modal tracking
                  showTracking();
                }
              }}
            >
              {item.status === "PAID"
                ? t("orders.set_pick_up").toUpperCase()
                : t("orders.tracking").toUpperCase()}
            </button>
          ) : null}
          {item.status === "ON_DELIVERY" && (
            <button
              className="button ml-2 hover:bg-gray-primary hover:text-green-secondary text-xs tracking-widest font-bold text-gray-primary border-2 border-green-secondary bg-green-secondary border-opacity-40 rounded-xl p-1 px-3"
              onClick={() => {
                getResi();
              }}
            >
              {t("general.print_label").toUpperCase()}
            </button>
          )}
        </div>
      </div>
      <div className="w-full my-2" />
      <div className="flex">
        <div className="flex-1 mr-2">
          {!showMore ? (
            <OrderProduct product={item.product[0]} />
          ) : (
            <>
              {item.product.map((product, index) => (
                <li key={index}>
                  <OrderProduct product={product} />
                </li>
              ))}
            </>
          )}
          {item.product.length > 1 ? (
            <button
              className="w-full text-center border-t p-1"
              onClick={() => {
                setShowMore(!showMore);
              }}
            >
              {!showMore
                ? t("orders.see_more", { params: item.product.length - 1 })
                : t("orders.see_less")}
            </button>
          ) : ""}
        </div>
        <div className="flex-1">
          {/* <div className="mb-3">
            <p className="text-gray-secondary">{t("management.customer_name")}</p>
            <p>{item.customerName}</p>
          </div> */}
          <div>
            <p className="text-gray-secondary">{t("management.address")}</p>
            <p>{item.address}</p>
          </div>
        </div>
        <div className="flex flex-col flex-1 ml-2">
          <div className="flex flex-1 justify-between">
            <div className="flex-1">
              <p className="text-gray-secondary">{t("orders.courier")}</p>
              <p>{item.courier}</p>
            </div>
            <div className="flex-1">
              {item.receivedBy !== "" ? (
                <>
                  <p className="text-gray-secondary">
                    {t("orders.received_by")}
                  </p>
                  <p>{item.receivedBy}</p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="flex flex-1 justify-between">
            <div className="flex-1">
              {item.receiptNumber !== "" ? (
                <>
                  <p className="text-gray-secondary">
                    {t("orders.receipt_number")}
                  </p>
                  <p>{item.receiptNumber}</p>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="flex-1">
              {item.receivedDate !== "" ? (
                <>
                  <p className="text-gray-secondary">
                    {t("orders.received_date")}
                  </p>
                  <p>{item.receivedDate}</p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full my-2" />
      {/* INI COMPLAINT DI HIDE  */}
      {/* {item.complaint !== "" && item.status === "COMPLAINED" ? (
        <div className="max-w-[50%] border-2 border-[#BBAB7F] bg-[#F5F0E178] rounded-xl my-2 p-2">
          <p className="mb-1">{t("orders.complaint")}</p>
          <p className="font-bold">{item.complaint}</p>
        </div>
      ) : ""} 
      */}
      <div className="flex w-full justify-between items-center px-3">
        <div className="relative flex items-center">
          <p>{t("dashboard.total_sales")}</p>
          <img
            onMouseEnter={() => {
              setShowInfo(true);
            }}
            onMouseLeave={() => {
              setShowInfo(false);
            }}
            className="h-3 ml-1"
            src={process.env.REACT_APP_ASSETS_IMAGE + "info-icon.svg"}
            alt="info-icon"
          />
          {showInfo ? (
            <OrderInfo productList={item.product} shipping={item.shipping + item.insuranceFee} />
          ) : (
            <></>
          )}
        </div>
        <p className="text-md text-green-primary font-bold">
          Rp {GeneralUtility.addCommas(item.total)}
        </p>
      </div>
    </div>
  );
};

export default OrderItem;

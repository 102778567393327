import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import Action from "../redux/Action";
import PopupConfirmation from "../../../../../components/PopupConfirmation/PopupConfirmation";
import { setPopup } from "../redux/Reducer";
import InfiniteScroll from "react-infinite-scroller";
import ButtonSort from "../../../../../components/ButtonSort/ButtonSort";
import EmptyProduct from "../../EmptyProduct";
import ProductList from "./ProductList";

const Tabel = ({ onEdit }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const popup = useSelector((state) => state.reducer.popup);
  const id = useSelector((state) => state.reducer.id);
  const list = useSelector((state) => state.reducer.list);
  const search = useSelector((state) => state.reducer.search);
  const loading = useSelector((state) => state.reducer.loading);
  const currentPage = useSelector((state) => state.reducer.currentPage);
  const hasMoreItems = useSelector((state) => state.reducer.hasMoreItems);

  const sortBy = useSelector((state) => state.reducer.sortBy);
  const sortByDirection = useSelector((state) => state.reducer.sortByDirection);

  const categoryId = useSelector((state) => state.reducer.categoryId);

  let filterTimeout;
  return (
    <div className="md:h-[31em] overflow-y-auto overflow-x-hidden">
      <InfiniteScroll
        loadMore={async () => {
          if (!loading) {
            Action.getList(dispatch, {
              currentList: list,
              offset: currentPage,
              search: search,
              categoryId: categoryId,
              sortBy: sortBy,
              sortByDirection: sortByDirection,
            });
          }
        }}
        hasMore={hasMoreItems}
        loader={<Skeleton key={0} height={"70px"} />}
        useWindow={false}
        threshold={100}
      >
        <table className="table-auto w-full text-left">
          <thead>
            <tr className="text-green-secondary font-semibold">
              <th className="p-2 my-2">
                <ButtonSort
                  title={t("products.product_info")}
                  isAsc={
                    sortBy === "brand"
                      ? sortByDirection === "asc"
                        ? true
                        : false
                      : false
                  }
                  isDesc={
                    sortBy === "brand"
                      ? sortByDirection === "desc"
                        ? true
                        : false
                      : false
                  }
                  sortAsc={() => {
                    clearTimeout(filterTimeout);
                    filterTimeout = setTimeout(() => {
                      Action.sortTable(dispatch, "brand", "asc");
                    }, 100);
                  }}
                  sortDesc={() => {
                    clearTimeout(filterTimeout);
                    filterTimeout = setTimeout(() => {
                      Action.sortTable(dispatch, "brand", "desc");
                    }, 100);
                  }}
                />
              </th>
              <th>
                <ButtonSort
                  title={t("products.price")}
                  isAsc={
                    sortBy === "price"
                      ? sortByDirection === "asc"
                        ? true
                        : false
                      : false
                  }
                  isDesc={
                    sortBy === "price"
                      ? sortByDirection === "desc"
                        ? true
                        : false
                      : false
                  }
                  sortAsc={() => {
                    clearTimeout(filterTimeout);
                    filterTimeout = setTimeout(() => {
                      Action.sortTable(dispatch, "price", "asc");
                    }, 100);
                  }}
                  sortDesc={() => {
                    clearTimeout(filterTimeout);
                    filterTimeout = setTimeout(() => {
                      Action.sortTable(dispatch, "price", "desc");
                    }, 100);
                  }}
                />
              </th>
              <th>
                <ButtonSort
                  title={t("products.promotional_price")}
                  isAsc={
                    sortBy === "promoPrice"
                      ? sortByDirection === "asc"
                        ? true
                        : false
                      : false
                  }
                  isDesc={
                    sortBy === "promoPrice"
                      ? sortByDirection === "desc"
                        ? true
                        : false
                      : false
                  }
                  sortAsc={() => {
                    clearTimeout(filterTimeout);
                    filterTimeout = setTimeout(() => {
                      Action.sortTable(dispatch, "promoPrice", "asc");
                    }, 100);
                  }}
                  sortDesc={() => {
                    clearTimeout(filterTimeout);
                    filterTimeout = setTimeout(() => {
                      Action.sortTable(dispatch, "promoPrice", "desc");
                    }, 100);
                  }}
                />
              </th>
              <th>
                <ButtonSort
                  title={t("products.stock")}
                  isAsc={
                    sortBy === "stock"
                      ? sortByDirection === "asc"
                        ? true
                        : false
                      : false
                  }
                  isDesc={
                    sortBy === "stock"
                      ? sortByDirection === "desc"
                        ? true
                        : false
                      : false
                  }
                  sortAsc={() => {
                    clearTimeout(filterTimeout);
                    filterTimeout = setTimeout(() => {
                      Action.sortTable(dispatch, "stock", "asc");
                    }, 100);
                  }}
                  sortDesc={() => {
                    clearTimeout(filterTimeout);
                    filterTimeout = setTimeout(() => {
                      Action.sortTable(dispatch, "stock", "desc");
                    }, 100);
                  }}
                />
              </th>
              <th>{t("products.active")}</th>
            </tr>
          </thead>

          <tbody className="font-medium">
            {list.map((item, index) => (
              <ProductList
                item={item}
                index={index}
                search={search}
                Action={Action}
                onEdit={onEdit}
              />
            ))}
          </tbody>
        </table>
        {list.length === 0 ? <EmptyProduct /> : null}
      </InfiniteScroll>

      {popup === true ? (
        <PopupConfirmation
          title={t("products.delete_title")}
          subtitle={t("products.delete_subtitle", {
            name: id.name,
          })}
          onCancel={() => {
            dispatch(setPopup(false));
          }}
          onDelete={() => {
            Action.onDelete(dispatch, id.id, search, sortBy, sortByDirection);
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Tabel;

import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import AppLayout from "../../components/layout/applayout/AppLayout";
import AuthLayout from "../../components/layout/authlayout/AuthLayout";
import RouteName from "../../services/routename";
import StoreHelper from "../../services/store-helper";
import AccountSettings from "../accountsettings/AccountSettings";
import UseAuth from "../auth/Auth";
import ForgotPassword from "../auth/forgotpassword/ForgotPassword";
import Login from "../auth/login/Login";
import NewPassword from "../auth/newpassword/NewPassword";
import Dashboard from "../dashboard/Dashboard";
import HelpCenter from "../helpcenter/HelpCenter";
import NotFound from "../notfound/NotFound";
import reducerSlice from "./redux/Reducer";
import { setTranslations, setDefaultLanguage } from "react-multi-lang";
import en from "../../translations/en.json";
import id from "../../translations/id.json";
import Action from "./redux/Action";
import Register from "../auth/register/Register";
import MerchantApplication from "../auth/merchantapplication/MerchantApplication";
import MerchantReject from "../merchantreject/MerchantReject";
import Orders from "../orders/Orders";
import Product from "../product/Product";
import Balance from "../balance/Balance";
import NewPasswordCustomer from "../auth/newpasswordcustomer/NewPassword";
import Cookies from "js-cookie";
import JneLabel from "../../components/JneLabel/JneLabel";
import Complaint from "../complaint/Complaint";

const Base = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  const MainComponent = () => {
    const translation = useSelector((state) => state.reducer.translation);
    const dispatch = useDispatch();
    setTranslations({ en, id });
    setDefaultLanguage(translation);
    const isAuthenticated = UseAuth.getStatus();

    useEffect(() => {
      Cookies.set("language", translation);
    }, []);

    return (
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path={RouteName.login} element={<Login />} />
          <Route path={RouteName.forgotpassword} element={<ForgotPassword />} />
          <Route path={RouteName.newpassword} element={<NewPassword />} />
          <Route path={RouteName.newpasswordcustomer} element={<NewPasswordCustomer />} />
        </Route>

        <Route path={RouteName.register} element={<Register />} />
        <Route path={RouteName.merchantapplication} element={<MerchantApplication />} />
        <Route path={RouteName.merchantreject} element={<MerchantReject />} />
        <Route path="*" element={<NotFound />} />

        {isAuthenticated === 0 ? (
          <>
            <Route element={<AppLayout />}>
              <Route
                path={RouteName.dashboard}
                element={
                  <Dashboard
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(
                        dispatch,
                        title,
                        RouteName.dashboard
                      );
                    }}
                  />
                }
              />
              {/* {UseAuth.checkBuybackCompability() ? (
                  <Route
                    path={RouteName.buyback}
                    element={
                      <Buyback
                        getBreadcrums={(title) => {
                          Action.getBreadcrums(
                            dispatch,
                            title,
                            RouteName.buyback
                          );
                        }}
                      />
                    }
                  />
                ) : null} */}
              <Route
                path={RouteName.helpcenter}
                element={
                  <HelpCenter
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(
                        dispatch,
                        title,
                        RouteName.helpcenter
                      );
                    }}
                  />
                }
              />
              <Route
                path={RouteName.accountsettings}
                element={
                  <AccountSettings
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(
                        dispatch,
                        title,
                        RouteName.accountsettings
                      );
                    }}
                  />
                }
              />
              <Route
                path={RouteName.orders}
                element={
                  <Orders
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(dispatch, title, RouteName.orders);
                    }}
                  />
                }
              />
              <Route
                path={RouteName.products}
                element={
                  <Product
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(
                        dispatch,
                        title,
                        RouteName.products
                      );
                    }}
                  />
                }
              />
              <Route
                path={RouteName.balance}
                element={
                  <Balance
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(
                        dispatch,
                        title,
                        RouteName.balance
                      );
                    }}
                  />
                }
              />
              <Route
                path={RouteName.complaint}
                element={
                  <Complaint
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(
                        dispatch,
                        title,
                        RouteName.complaint
                      );
                    }}
                  />
                }
              />
            </Route>
            <Route
              path={RouteName.jnelabel + "/:inv"}
              element={<JneLabel />}
            />
          </>
        ) : (
          ""
        )}
      </Routes>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default Base;

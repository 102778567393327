import { useDispatch, useSelector } from "react-redux";
import InfiniteScrollComponent from "../../../../../components/InfiniteScrollComponent/InfiniteScrollComponent";
import InvoiceModal from "../../InvoiceModal";
import OrderItem from "../../OrderItem/OrderItem";
import TrackingModal from "../../TrackingModal";
import Action from "../redux/Action";
import EmptyOrder from "../../EmptyOrder";

const AllOrderList = ({ search, sort, startDateFilter, endDateFilter }) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.reducer.list);
  const hasMoreItems = useSelector((state) => state.reducer.hasMoreItems);
  const loadingData = useSelector((state) => state.reducer.loadingData);
  const showInvoiceModal = useSelector(
    (state) => state.reducer.showInvoiceModal
  );
  const invoiceDetail = useSelector((state) => state.reducer.invoiceDetail);
  const showTrackingModal = useSelector(
    (state) => state.reducer.showTrackingModal
  );
  const trackingDetail = useSelector((state) => state.reducer.trackingDetail);
  const isEmptyList = useSelector((state) => state.reducer.isEmptyList);
  const page = useSelector((state) => state.reducer.page);

  return (
    <>
      {showInvoiceModal ? (
        <InvoiceModal
          onCancel={() => {
            Action.showInvoiceModal(dispatch, { showModal: false });
          }}
          title={invoiceDetail.invoice}
          invoiceDetail={invoiceDetail}
        />
      ) : (
        <></>
      )}
      {showTrackingModal ? (
        <TrackingModal
          onCancel={() => {
            Action.showTrackingModal(dispatch, { showModal: false });
          }}
          loadingData={loadingData}
          trackingDetail={trackingDetail}
        />
      ) : null}
      <div className="max-h-[26.5rem] overflow-y-auto">
        {!isEmptyList ? (
          <InfiniteScrollComponent
            loadMore={async () => {
              if (!loadingData) {
                Action.getList(dispatch, {
                  currentList: list,
                  search: search,
                  sort: sort,
                  startDateFilter: startDateFilter,
                  endDateFilter: endDateFilter,
                  page: page,
                });
              }
            }}
            hasMore={hasMoreItems}
            useWindow={false}
            threshold={100}
            loader={<div key={0} height={"464px"} />}
            children={
              <ul>
                {list.map((item, index) => (
                  <li key={index}>
                    <OrderItem
                      item={item}
                      showInvoice={() => {
                        Action.showInvoiceModal(dispatch, {
                          showModal: true,
                          invoiceDetail: item,
                        });
                      }}
                      showTracking={() => {
                        Action.showTrackingModal(dispatch, {
                          showModal: true,
                          id: item.id,
                        });
                      }}
                      setPickUp={() => {
                        Action.setPickUp(dispatch, {
                          id: item.id,
                        });
                      }}
                      getResi={() => {
                        Action.getResi(item.invoice);
                      }}
                    />
                  </li>
                ))}
              </ul>
            }
          ></InfiniteScrollComponent>
        ) : (
          <EmptyOrder />
        )}
      </div>
    </>
  );
};

export default AllOrderList;
